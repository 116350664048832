.modal {
  &-wrapper {
    display: flex;
    align-items: center;
    align-content: center;
    background: rgba(0, 0, 0, 0.5);
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
  }

  &-content {
    background: #fff;
    margin: 20px auto;
    max-width: 800px;
    padding: 0;
    position: relative;
    border: 1 px solid var(--border);
    max-height: 100vh;
  }

  &-close {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
    z-index: 999;
  }
}
