:root {
  --brand: #4155c6;
  --brand-hover: #6a7ad2;
  --brand-disabled: #343d72;
  --link: #8e9cf6;
  --link-hover: #bdc5fa;
  --link-disabled: #5c6076;
  --material: #222430;
  --material-hover: #292d42;
  --material-disabled: #090a0b;
  --primary: #2d3146;
  --primary-hover: #424866;
  --primary-disabled: #1c1e2b;
  --bg: #14151e;
  --border: #14151e;
  --text-for-material: #fff;
  --text-for-material-disabled: #b2b2b2;
  --sub-text: #868c98;
  --icon: #ccc;
  --icon-hover: #fff;
  --text-for-brand: #fff;
  --text-for-brand-disabled: #898989;
  --odd: #e7cf90;
  --amber: #ffb822;
  --red: #f26f89;
  --green: #01b64f;
  --shadow: #000;
  --disable-button: #000;
  --white: #fff;
}

*,
body,
html {
  margin: 0;
  padding: 0;
}

*,
:before,
:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h2 {
  font-size: 16px;
  color: var(--text-for-material);
  margin-top: 0;
  margin-bottom: 8px;
  padding: 0 16px;
}
