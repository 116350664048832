.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 0 16px;

  &-button {
    width: 100%;
    background-color: var(--primary);
    color: var(--text-for-material);
    padding: 12px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    text-align: left;
    position: relative;

    &:before {
      content: "";
      display: flex;
      width: 0;
      height: 0;
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      color: var(--text-for-material);
      border-style: solid;
      border-width: 8px 6px 0 6px;
      border-color: var(--text-for-material) transparent transparent transparent;
      z-index: 1;
    }

    &:hover {
      background-color: var(--primary-hover);
    }
  }
  &-content {
    position: absolute;
    background-color: var(--primary);
    width: calc(100% - 32px);
    z-index: 2;
    border-radius: 4px;
    overflow: hidden;

    button {
      -webkit-appearance: none;
      border: none;
      width: 100%;
      background-color: var(--primary);
      color: var(--text-for-material);
      padding: 12px;
      text-decoration: none;
      display: block;
      cursor: pointer;
      text-align: left;

      &:hover {
        background-color: var(--primary-hover);
      }
    }
  }
}
