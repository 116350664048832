.buttons-block {
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--bg);
  padding: 16px;

  button {
    & ~ button {
      margin-top: 8px;
    }
  }
}
