.page-content {
  display: flex;
  background-color: var(--bg);
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.frame-wrapper {
  min-width: 0;
  flex: 0 0 80%;
}

.sidebar-wrapper {
  min-width: 0;
  flex: 0 0 20%;
}
