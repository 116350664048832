.color-fields {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 16px;

  &-title {
    padding: 0;
  }
}
