.color-field {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: var(--text-for-material);
  gap: 8px;

  &-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 0;
    flex: 1 1;
  }

  &-input {
    flex: 0 0 40px;
  }
}
