.button {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: bold;
  outline: none;
  border: none;
  -webkit-appearance: none;
  cursor: pointer;
  &.primary {
    background: var(--brand);
    color: var(--text-for-brand);
  }
  &.secondary {
    background: var(--primary);
    color: var(--text-for-material);
  }
  &.success {
    background: var(--green);
    color: var(--text-for-brand);
  }
  &.alert {
    background: var(--red);
    color: var(--text-for-brand);
  }
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.3;
  }
}
